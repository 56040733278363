export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    access_nivel: [1, 2],
  },
  {
    title: 'Cadastros',
    icon: 'FileTextIcon',
    access_nivel: [1],
    children: [
      {
        title: 'Usuários',
        route: 'list-users',
        access_nivel: [1],
      },
      {
        title: 'Empresas',
        route: 'list-companies',
        access_nivel: [1],
      },
      {
        title: 'Produtos',
        route: 'list-products',
        access_nivel: [1],
      },
    ],
  },
  {
    title: 'Gerar Etiquetas',
    route: 'generate-tags',
    icon: 'TagIcon',
    access_nivel: [1, 2],
  },
  {
    title: 'Estoque',
    route: 'stock',
    icon: 'GridIcon',
    access_nivel: [1, 2],
  },
  {
    title: 'Estoque Manual',
    route: 'manual_stock',
    icon: 'GridIcon',
    access_nivel: [1, 2],
  },
  {
    title: 'Confirmar Estoque',
    route: 'confirm_stock',
    icon: 'GridIcon',
    access_nivel: [1, 2],
  },
]
